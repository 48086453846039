<script lang="ts" setup>
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { Link } from '~/types'

const props = defineProps<{
  date: Date
  title: string
  location: string
  link: Link
  dim?: boolean
}>()

const date = computed(() => (props.date ? format(props.date, 'dd MMM', { locale: de }) : ''))
</script>

<template>
  <LinkListLargeItem :dim="!!dim" :link="link">
    <div class="date">
      <h5>{{ date }}</h5>
    </div>
    <div class="info">
      <h4 v-if="title" class="title">{{ title }}</h4>
      <p class="location text-small"><Icon name="pin" />{{ location }}</p>
    </div>
  </LinkListLargeItem>
</template>

<style lang="scss" scoped>
.item.item {
  @include breakpoint(ml) {
    grid-template-columns: auto 1fr;
  }

  @include breakpoint(ts) {
    grid-template-columns: auto 1fr auto;
  }

  @include hover {
    .date {
      --color-bg: var(--c-background-medium);
      --scale: 0.9;
      h5 {
        color: var(--c-blue-rollover);
      }
    }
  }

  :deep(.button) {
    display: none;
    @include breakpoint(ts) {
      display: block;
    }
  }
}

.date {
  --color-bg: var(--c-background-light);
  --scale: 1;
  @include fluid(--size, 70px, 90px);

  position: relative;
  width: var(--size);
  height: var(--size);

  display: grid;
  place-content: center;

  &:before {
    content: '';
    @include fill;
    background-color: var(--color-bg);
    transform: scale(var(--scale));
    @include transitions(transform, background-color);
  }

  h5 {
    position: relative;
    margin-bottom: 0;
    text-align: center;
    @include fluid(width, 35px, 40px);
    overflow-wrap: normal;
  }
}
.title {
  margin-bottom: 0;
}

.location {
  margin-bottom: 0;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 5px;
}
</style>
